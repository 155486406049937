import { Icon } from "@chakra-ui/react";

export const MentalHealthIcon = () => (
  <Icon width="1.5rem" height="1.5rem" viewBox="-2 0 24 24">
    <path
      d="M8.8994 0C11.0362 0.000139319 13.1 0.77757 14.7058 2.18728C16.3117 3.59699 17.3499 5.5427 17.6268 7.6615L20.1018 11.5544C20.2646 11.8107 20.2316 12.1924 19.8543 12.3552L17.6994 13.277V16.5C17.6994 17.0835 17.4676 17.6431 17.055 18.0556C16.6425 18.4682 16.0829 18.7 15.4994 18.7H13.3005L13.2994 22H3.3994V17.9366C3.3994 16.6386 2.9198 15.4099 2.031 14.3011C0.994529 13.007 0.344746 11.4464 0.156511 9.79907C-0.0317251 8.15177 0.249245 6.4848 0.96705 4.99022C1.68485 3.49564 2.81029 2.23424 4.2137 1.35136C5.6171 0.468485 7.24138 4.08752e-05 8.8994 0ZM8.8994 2.2C7.65594 2.19995 6.43776 2.55117 5.38518 3.21321C4.33261 3.87524 3.48848 4.82116 2.95002 5.94199C2.41156 7.06282 2.20069 8.31296 2.34169 9.5484C2.48269 10.7838 2.96983 11.9543 3.747 12.925C4.9504 14.4254 5.5994 16.1337 5.5994 17.9366V19.8H11.0994L11.1016 16.5H15.4994V11.8272L17.2044 11.0968L15.5071 8.4293L15.4444 7.9431C15.2362 6.3546 14.4573 4.89604 13.2531 3.83937C12.0489 2.78269 10.5015 2.20002 8.8994 2.2ZM8.3164 6.3393L8.8994 6.9223L9.4824 6.3393C9.65994 6.15541 9.87232 6.00871 10.1072 5.90778C10.342 5.80684 10.5946 5.75369 10.8502 5.75142C11.1058 5.74914 11.3593 5.7978 11.5959 5.89454C11.8325 5.99128 12.0474 6.13418 12.2282 6.31489C12.409 6.49559 12.552 6.71049 12.6488 6.94705C12.7456 7.1836 12.7944 7.43708 12.7922 7.69268C12.7901 7.94828 12.737 8.20089 12.6362 8.43576C12.5353 8.67064 12.3887 8.88309 12.2049 9.0607L8.8994 12.3673L5.5939 9.0607C5.41008 8.88309 5.26347 8.67064 5.16263 8.43576C5.06179 8.20089 5.00874 7.94828 5.00657 7.69268C5.0044 7.43708 5.05316 7.1836 5.15 6.94705C5.24683 6.71049 5.38981 6.49559 5.57059 6.31489C5.75137 6.13418 5.96633 5.99128 6.20293 5.89454C6.43952 5.7978 6.69302 5.74914 6.94862 5.75142C7.20421 5.75369 7.4568 5.80684 7.69164 5.90778C7.92648 6.00871 8.13886 6.15541 8.3164 6.3393Z"
      fill="currentColor"
    />
  </Icon>
);
