import { Icon } from "@chakra-ui/react";

export const MapLayersIcon = () => (
  <Icon
    viewBox="0 0 24 24"
    color="gray.600"
    preserveAspectRatio="xMaxYMax meet"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M11.5144 2.12584C11.8164 1.95805 12.1836 1.95805 12.4856 2.12584L21.4856 7.12584C21.8031 7.30221 22 7.63683 22 8C22 8.36317 21.8031 8.69779 21.4856 8.87416L12.4856 13.8742C12.1836 14.0419 11.8164 14.0419 11.5144 13.8742L2.51436 8.87416C2.19689 8.69779 2 8.36317 2 8C2 7.63683 2.19689 7.30221 2.51436 7.12584L11.5144 2.12584ZM5.05913 8L12 11.856L18.9409 8L12 4.14396L5.05913 8Z"
      fill="currentColor"
    ></path>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M2.12584 11.5144C2.39405 11.0316 3.00286 10.8576 3.48564 11.1258L12 15.856L20.5144 11.1258C20.9971 10.8576 21.6059 11.0316 21.8742 11.5144C22.1424 11.9971 21.9684 12.6059 21.4856 12.8742L12.4856 17.8742C12.1836 18.0419 11.8164 18.0419 11.5144 17.8742L2.51436 12.8742C2.03157 12.6059 1.85763 11.9971 2.12584 11.5144Z"
      fill="currentColor"
    ></path>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="M2.12584 15.5144C2.39405 15.0316 3.00286 14.8576 3.48564 15.1258L12 19.856L20.5144 15.1258C20.9971 14.8576 21.6059 15.0316 21.8742 15.5144C22.1424 15.9971 21.9684 16.6059 21.4856 16.8742L12.4856 21.8742C12.1836 22.0419 11.8164 22.0419 11.5144 21.8742L2.51436 16.8742C2.03157 16.6059 1.85763 15.9971 2.12584 15.5144Z"
      fill="currentColor"
    ></path>
  </Icon>
);
