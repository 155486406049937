import { Icon } from "@chakra-ui/react";

export const BuildingHouseIcon = () => (
  <Icon width="1.5rem" height="1.5rem" viewBox="0 0 24 24">
    <path
      d="M18.9998 2H8.99979C7.89679 2 6.99979 2.897 6.99979 4V9.586L2.29279 14.293C2.15298 14.4329 2.05777 14.611 2.0192 14.805C1.98064 14.9989 2.00044 15.2 2.07611 15.3827C2.15178 15.5654 2.27992 15.7215 2.44433 15.8314C2.60874 15.9413 2.80204 16 2.99979 16V21C2.99979 21.2652 3.10514 21.5196 3.29268 21.7071C3.48022 21.8946 3.73457 22 3.99979 22H19.9998C20.265 22 20.5194 21.8946 20.7069 21.7071C20.8944 21.5196 20.9998 21.2652 20.9998 21V4C20.9998 2.897 20.1028 2 18.9998 2ZM10.9998 20H4.99979V14.414L7.99979 11.414L10.9998 14.414V20ZM18.9998 20H12.9998V16C13.1978 16.0004 13.3914 15.942 13.5561 15.8322C13.7208 15.7224 13.8492 15.5662 13.925 15.3833C14.0007 15.2004 14.0204 14.9991 13.9816 14.805C13.9427 14.6109 13.8471 14.4327 13.7068 14.293L8.99979 9.586V4H18.9998V20Z"
      fill="currentColor"
    />
    <path
      d="M11 6H13V8H11V6ZM15 6H17V8H15V6ZM15 10.031H17V12H15V10.031ZM15 14H17V16H15V14ZM7 15H9V17H7V15Z"
      fill="currentColor"
    />
  </Icon>
);
